import types from "../types";

//This Guard(?.) is for react-snap or Pre-rendering

const initialState = {
  profileProgress: 0, // a value ranging from 0 to 100
  _id: "",
  email: "",
  userId: "",
  awards: [],
  skills: [],
  social: [],
  contact: {
    birthDate: "",
    address: {
      addressText: "",
      pincode: "",
      city: "",
      country: "",
    },
    mobileNo: "",
  },
  mobileNo: "",
  projects: [],
  education: [],
  experience: [],
  certificates: [],
  alternateEmail: "",
  enrolledToBatch: false,
};

const UserDetailsReducer = (state = initialState, action) => {
  // console.log("reached here", action);
  switch (action.type) {
    case types.userDetailTypes.FETCH_USER_DETAILS:
      return {
        ...state,
        _id: action.payload?._id,
        email: action.payload?.email,
        userId: action.payload?.userId,
        awards: [...(action.payload?.awards || [])],
        skills: [...(action.payload?.skills || [])],
        social: [...(action.payload?.social || [])],
        contact: {
          ...state.contact,
          birthDate: action.payload?.contact?.birthDate,
          address: {
            ...state.contact.address,
            addressText: action?.payload?.contact?.address?.addressText,
            pincode: action.payload?.contact?.address?.pincode,
            city: action.payload?.contact?.address?.city,
            country: action.payload?.contact?.address?.country,
          },
        },
        projects: [...(action.payload?.projects || [])],
        education: [...(action.payload?.education || [])],
        experience: [...(action.payload?.experience || [])],
        certificates: [...(action.payload?.certificates || [])],
        alternateEmail: action.payload?.alternateEmail,
        enrolledToBatch: action.payload?.enrolledToBatch,
        batchId: action.payload?.batchId,
        isTourSeen: action.payload?.isTourSeen,
        cohortPurchased: action.payload?.cohortPurchased,
      };
    case types.userDetailTypes.UPDATE_PROFILE_PROGRESS:
      return {
        ...state,
        profileProgress: action.payload.profileProgress,
      };
    case types.userDetailTypes.RESET_USER_DETAILS:
      return {
        profileProgress: 0,
        _id: "",
        email: "",
        userId: "",
        awards: [],
        skills: [],
        social: [],
        contact: {
          ...state.contact,
          birthDate: "",
          address: {
            addressText: "",
            pincode: "",
            city: "",
            country: "",
          },
        },
        projects: [],
        education: [],
        experience: [],
        certificates: [],
        enrolledToBatch: false,
      };
    default:
      return state;
  }
};

export default UserDetailsReducer;
